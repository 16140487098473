/* 
  ### Chart Default Data ### 
  Predefined component's behaviors for the Filter & Action
*/


//00_Home
  
//01.Project
//02_Engineering
//03.Planning
//04_Construction
//05_Procurement
//06_QualityControl
//08.SystemCompletion
//09.Temp

export default {

  //00_Home

  //01.Project
  //02_Engineering
  //03.Planning
  //04_Construction
  //05_Procurement
  //06_QualityControl
  //08.SystemCompletion
  //09.Temp
}
